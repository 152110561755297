import { useDispatch, useSelector } from 'react-redux';
import { MODAL_STATUS_ERROR } from '../../../../variables';
import { handleCloseMenu } from '../../../../utils/locationConfig';
import AnimatedStatus from '../../AnimatedStatus';
import { useRouter } from 'next/router';
import routes from '../../../../utils/routes.json';
import { useEffect } from 'react';
import { setGeneralRegistrationError } from '../../../../store/reducers/register';
import { openCustomerSupportWidget } from '@tlf-e/brand-utils';

const RegistrationErrorView = ({ title, details, queries = '' }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const paths = useSelector((state) => state.global.paths);
    const router = useRouter();
    const dispatch = useDispatch();

    const redirectToContactPage = () => {
        router.push({ pathname: routes.contact, query: {} }, paths[routes.contact]);
    };

    const contactSupport = () => {
        handleCloseMenu();
        openCustomerSupportWidget({ callback: redirectToContactPage });
    };

    useEffect(() => {
        return () => {
            dispatch(setGeneralRegistrationError(false));
        };
    }, []);

    return (
        <div className="registration-error-view" data-testid="registration-error-view">
            <div className="registration-error-view-container">
                <div className="logo-wrapper">
                    <AnimatedStatus status={MODAL_STATUS_ERROR} />
                </div>
                <div className="registration-error-view-body">
                    <div className="registration-error-view-body-title">{title}</div>
                    <div className="registration-error-view-body-content">
                        <div className="content-text">{details}</div>
                        {queries && <div className="content-queries">{queries}</div>}
                    </div>
                </div>
                <div className="form--btns">
                    <button
                        type="button"
                        className="btn btn-cancel btn-modern"
                        data-testid="close_registration_error_view"
                        onClick={handleCloseMenu}
                    >
                        {tr['blocked_registration_later']}
                    </button>
                    <button
                        type="submit"
                        data-testid="contact_support"
                        className="btn btn-submit btn-modern"
                        disabled={false}
                        onClick={contactSupport}
                    >
                        {tr['deposit.flow.result.fail.contact_support']}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RegistrationErrorView;
