import { useEffect, useRef, useState, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WheelOfFortune } from '@tlf-e/react-components';
import WinModal from './win-modal';
import WheelErrorModal from './WheelErrorModal';
import AnimatedLogo from './AnimatedLogo';
import { wheelOfFortuneWorkers } from '../../../../services/http';
import { getTimeToSpin, getWofStandings, setWheelMode } from '../../../../store/reducers/wheel-of-fortune';
import {
    WHEEL_LOCATION_PAGE,
    WHEEL_LOCATION_WIDGET,
    WHEEL_ORIGIN_DIRECT,
    WHEEL_TIMER_STATUS_COOLDOWN,
    WHEEL_TIMER_STATUS_SPIN_READY,
    WOF_STANDINGS_RELOAD,
    WOF_WHEEL_MODE_LOADING,
    WOF_WHEEL_MODE_SPIN,
    WOF_WHEEL_MODE_TIMER,
    WOF_WIN_TYPE_BONUS,
    WOF_WIN_TYPE_POINTS
} from '../../../../variables';
import debounce from 'lodash/debounce';
import { dataLayerPush } from '@tlf-e/brand-utils';
import { getWheelTimer } from '../../../../utils/wheelOfFortuneHelper';

const winAmounts = {
    [WOF_WIN_TYPE_BONUS]: 'quantity',
    [WOF_WIN_TYPE_POINTS]: 'value'
};

const eventsByPlaces = {
    [WHEEL_LOCATION_PAGE]: 'wf_Page_Open',
    [WHEEL_LOCATION_WIDGET]: 'WF_Widget_Open'
};

const Wheel = ({ size = 200, place }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const wof = useSelector((state) => state.wheel_of_fortune.wof);
    const wheelOrigin = useSelector((state) => state.wheel_of_fortune.wheelOrigin);
    const { id: playerId } = useSelector((state) => state.user.information);
    const wheelRef = useRef();
    const timerRef = useRef(null);
    const [countdown, setCountdown] = useState(0);
    //THERE ARE 3 POSSIBLE MODES (spin, spin-again, timer);
    const [mode, setMode] = useState(WOF_WHEEL_MODE_LOADING);
    // This is the state of wheel, it is stored on finished, so we allow the wheel to be rerendered
    // while keeping the previous winner intact, it is updated on "onFinished" callback
    const [wheelState, setWheelState] = useState([]);
    // User interaction modals (win, error)
    const [winModal, setWinModal] = useState({
        win: 0,
        type: '',
        isOpen: false
    });
    const [isErrorModalOpened, setErrorModalOpened] = useState(false);
    const uniqueSegments = [...new Set([...wof.options])];
    const dispatch = useDispatch();
    // Show win modal in 1s after the wheel finishes spinning
    const openDebouncedWinModal = debounce((value) => setWinModal(value), 1000);
    const handleWinModal = useCallback((value) => openDebouncedWinModal(value), []);
    const spinResultTimeout = 2000;
    // Add debounce on spin success
    const debouncedSpinSuccess = debounce((res) => {
        const { success, result, timeToSpin } = res.data.data;
        if (success) {
            stopWheelHandler(result);
        } else if (timeToSpin) {
            cancelWheelHandler();
            handleTimeToSpin(timeToSpin);
        }
    }, spinResultTimeout);
    const handleSpinSuccess = useCallback((res) => debouncedSpinSuccess(res), []);
    // Add debounce on spin error
    const debouncedSpinError = debounce(() => {
        setErrorModalOpened(true); // OPENS ERROR MODAL VERIFICATION
        cancelWheelHandler();
    }, spinResultTimeout);
    const handleSpinError = useCallback(() => debouncedSpinError(), []);

    /**
     * MODES: [spin, spin-again, timer, loading]
     * REF_METHODS: [applyLoading, removeLoading, spinWheel, stopWheel, cancelSpin]
     * CALLBACKS: [onSpinClickCallback, onFinishHandler]
     * PROPS: [
     * segments -> List of segments as it comes from the API
     * spinText -> Text to display in the center of the circle in spin mode
     * size -> Size of the wheel
     * textFontFamily -> Font family of the text on the wheel
     * spinAgainText -> DEPRECATED text to display in the center of the circle in spin-again mode
     * timerText -> Text to display as label in timer mode
     * timeUntilNextSpin -> Time to display in timer mode
     * loadingText -> Text to display when loading
     * mode -> Operating mode of the wheel, possible values are MODES
     * wheelState -> The onFinished callback returns you a wheelState object, you can store it and pass it to the wheel as props to keep the wheel stopped in the same segment even when changing modes
     * onFinished -> Callback to be called when the wheel stops spinning, it will have {winningSegment, wheelState} as parameters
     * onSpinClick -> Callback to be called when the center button is clicked while on spin mode
     * ]
     */

    useEffect(() => {
        const origin =
            place === WHEEL_LOCATION_PAGE
                ? {
                    origin: wheelOrigin || WHEEL_ORIGIN_DIRECT
                }
                : {};
        fetchTimeToSpin({
            event: eventsByPlaces[place],
            ...origin
        });

        return () => {
            dispatch(
                getTimeToSpin(
                    wof.id,
                    () => null,
                    () => null
                )
            );
            removeTimer();
        };
    }, []);

    const removeTimer = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
    };

    /**
     * CONTROL FLOW
     */

    const fetchTimeToSpin = (eventData = {}) => {
        const pushEvent = (timerStatus) => {
            const extraData = eventData.origin ? { timer_status: timerStatus } : {};
            dataLayerPush({
                ...eventData,
                ...extraData,
                player_id: playerId
            });
        };

        const activateSpin = () => {
            pushEvent(WHEEL_TIMER_STATUS_SPIN_READY);
            removeLoading();
            spinCircleHandler(); // Should put to SPIN mode
        };
        const onSuccess = (timeToSpin) => {
            if (timeToSpin > 0) {
                pushEvent(WHEEL_TIMER_STATUS_COOLDOWN);
                return handleTimeToSpin(timeToSpin); // Should put to TIMER mode
            }
            activateSpin();
        };

        applyLoading(); // Should apply loading
        wof.id && dispatch(getTimeToSpin(wof.id, onSuccess, activateSpin));
    };

    const handleTimeToSpin = (timeToSpin) => {
        timerHandler();
        setCountdown(timeToSpin * 1000);
        timerRef.current = setInterval(
            () =>
                setCountdown((state) => {
                    const time = state - 1000;
                    if (time === 0) {
                        spinCircleHandler(); // Should put to SPIN AGAIN mode
                        removeTimer();
                    }
                    return time;
                }),
            1000
        );
    };

    const spinTheWheelHandler = () => {
        if (wheelRef.current) {
            spinWheelHandler();
            applyLoading();
            wheelOfFortuneWorkers.spinRequest({ wofId: wof.id }).then(handleSpinSuccess).catch(handleSpinError);
        }
    };

    /**
     * CHANGE MODES
     */
    const spinCircleHandler = () => {
        setMode(WOF_WHEEL_MODE_SPIN);
    };

    const timerHandler = () => {
        setMode(WOF_WHEEL_MODE_TIMER);
    };

    /**
     * METHODS
     */
    const applyLoading = () => {
        if (wheelRef.current) {
            wheelRef.current.applyLoading();
        }
    };
    const removeLoading = () => {
        //You only need to use this when the loading is applied but no changes are made to the mode
        if (wheelRef.current) {
            wheelRef.current.removeLoading();
        }
    };
    const spinWheelHandler = () => {
        if (wheelRef.current) {
            wheelRef.current.spinWheel();
        }
    };
    const stopWheelHandler = (segment) => {
        if (wheelRef.current) {
            wheelRef.current.stopWheel(segment);
        }
    };
    const cancelWheelHandler = () => {
        if (wheelRef.current) {
            wheelRef.current.cancelSpin();
        }
    };
    /**
     * CALLBACKS
     */
    const onSpinClickCallback = () => {
        spinTheWheelHandler();
    };

    const onFinishHandler = (results) => {
        if (results.winningSegment) {
            const type = results.winningSegment.type;
            const winField = winAmounts[type];
            const win = results.winningSegment[winField];
            if (win > 0) {
                handleWinModal({
                    win,
                    type,
                    isOpen: true
                }); // OPENS CONGRATS MODAL
            }
        }

        setWheelState(results.wheelState);
        fetchTimeToSpin({
            event: 'wf_Spin',
            result: results.winningSegment?.name,
            place,
            timestamp: new Date().toLocaleString()
        });
    };

    const onModeChangeHandler = (mode) => dispatch(setWheelMode(mode));

    const onCloseWinModal = () => {
        setWinModal((state) => ({
            ...state,
            isOpen: false
        }));
        dispatch(getWofStandings(wof.id, WOF_STANDINGS_RELOAD));
    };

    const retrySpinHandler = () => {
        const timer = setTimeout(() => {
            spinTheWheelHandler();
            clearTimeout(timer);
        }, 0);
    };

    return (
        <div className="content-wrapper">
            <AnimatedLogo />
            <div className="wheel-wrapper">
                <WheelOfFortune
                    ref={wheelRef}
                    segments={uniqueSegments}
                    spinText={tr['wof.wheel.label.spin']}
                    timerText={tr['wof.wheel.label.timer']}
                    loadingText={tr['wof.wheel.label.loading']}
                    size={size}
                    textFontFamily="Nunito"
                    onFinished={onFinishHandler}
                    mode={mode}
                    wheelState={wheelState}
                    timeUntilNextSpin={getWheelTimer(countdown)}
                    onSpinClick={onSpinClickCallback}
                    onModeChange={onModeChangeHandler}
                />
            </div>
            <WinModal win={winModal.win} type={winModal.type} isOpen={winModal.isOpen} onClose={onCloseWinModal} />
            <WheelErrorModal
                isOpen={isErrorModalOpened}
                setErrorModalOpened={setErrorModalOpened}
                spinTheWheelHandler={retrySpinHandler}
            />
        </div>
    );
};

export default memo(Wheel);
