import FavoritePanelNotification from '../desktop/casino-games/fragments/game/favoritePanel/fragments/FavoritePanelNotification';
import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Notification from '../ui/Notification';
import NotificationContent from '../common/deposit-modal/fragments/NotificationContent';
import ToastNotification from '../common/ToastNotification';
import Success from '../svg-icons/Success';
import AlertTriangle from '../svg-icons/AlertTriangle';
import Cross from '../svg-icons/Cross';
import { getTransactionStatus, setPendingStatusNotification } from '../../store/reducers/bank';
import { formatCurrency } from '../../utils/formatCurrency';
import { getModalUrl } from '../../utils/locationConfig';
import { initUpdateBankCard, resetUploadFile } from '../../store/reducers/user';
import {
    MODAL_DEPOSIT,
    NOTIFICATIONS_STATUS_ERROR,
    NOTIFICATIONS_STATUS_SUCCESS
} from '../../variables';
import { initClaimBonus } from '../../store/reducers/bonus';
import { resetFavoriteGameMethod } from '../../store/reducers/games';

export const withNotifications = (PageComponent) => {
    const WithNotifications = ({ ...props }) => {
        const tr = useSelector((state) => state.global.data.translations);
        const { loadingProgress } = useSelector((state) => state.global);
        const { favoriteGameMethod } = useSelector((state) => state.games);
        const { id: userId } = useSelector((state) => state.user.information);
        const { pendingStatusIntervalTime, pendingStatusNotification, pendingStatusId } = useSelector((state) => state.bank);
        const router = useRouter();
        const dispatch = useDispatch();
        const {
            claimBonusMessage,
            claimBonusSuccess,
            claimBonusFailed
        } = useSelector((state) => state.bonus);
        const { uploadStatus, updateBankCard } = useSelector((state) => state.user);

        useEffect(() => {
            if (pendingStatusIntervalTime) {
                const timer = setTimeout(() => {
                    dispatch(getTransactionStatus(pendingStatusId));
                }, pendingStatusIntervalTime);

                return () => clearTimeout(timer);
            }
        }, [pendingStatusIntervalTime]);

        const handleCloseNotification = (e, reason) => {
            if (reason !== 'clickaway') {
                dispatch(setPendingStatusNotification(''));
            }
        };

        const onCloseDocNotification = (e, reason) => {
            if (reason !== 'clickaway') {
                dispatch(resetUploadFile());
            }
        };

        const onCloseBankCardNotification = (e, reason) => {
            if (reason !== 'clickaway') {
                dispatch(initUpdateBankCard());
            }
        };

        const onCloseFavoriteNotification = (e, reason) => {
            if (reason !== 'clickaway') {
                dispatch(resetFavoriteGameMethod());
            }
        };

        const onCloseClaimBonusNotification = (e, reason) => {
            if (reason !== 'clickaway') {
                dispatch(initClaimBonus());
            }
        };

        return (
            <Fragment>
                <PageComponent {...props} />
                {loadingProgress === 100 && userId && (
                    <Fragment>
                        <Notification
                            isOpen={pendingStatusNotification === NOTIFICATIONS_STATUS_SUCCESS}
                            onClose={handleCloseNotification}
                            status={NOTIFICATIONS_STATUS_SUCCESS}
                            rootClass="transaction-snackbar"
                            showTimer
                        >
                            {({ autoHideCountdown, onClose }) => (
                                <NotificationContent autoHideCountdown={autoHideCountdown} onClose={onClose}>
                                    {({ timer, paymentInfo }) => (
                                        <div className="notification-content notification-content--timer">
                                            <div>
                                                <Success />
                                                <span>
                                                    <strong>{tr['pending_transaction.notification.success']} </strong>
                                                    {paymentInfo.amount && (
                                                        <span>
                                                            {tr['pending_transaction.notification.amount'].replace(
                                                                '##AMOUNT##',
                                                                formatCurrency(paymentInfo.amount, paymentInfo.currency)
                                                            )}
                                                        </span>
                                                    )}
                                                </span>
                                            </div>
                                            {timer > 0 && (
                                                <div className="notification-timer">
                                                    {tr['pending_transaction.notification.countdown'].replace(
                                                        '##SECONDS##',
                                                        timer
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </NotificationContent>
                            )}
                        </Notification>
                        <Notification
                            isOpen={pendingStatusNotification === NOTIFICATIONS_STATUS_ERROR}
                            onClose={handleCloseNotification}
                            status={NOTIFICATIONS_STATUS_ERROR}
                            autoHideDuration={null}
                            rootClass="transaction-snackbar"
                        >
                            {() => (
                                <NotificationContent>
                                    {({ paymentInfo }) => (
                                        <div className="notification-content">
                                            <div>
                                                <AlertTriangle />
                                                <span>
                                                    <strong>{tr['pending_transaction.notification.failed']} </strong>
                                                    {paymentInfo.amount && (
                                                        <span>
                                                            {tr['pending_transaction.notification.amount'].replace(
                                                                '##AMOUNT##',
                                                                formatCurrency(paymentInfo.amount, paymentInfo.currency)
                                                            )}
                                                        </span>
                                                    )}
                                                </span>
                                            </div>
                                            <div>
                                                <Link {...getModalUrl(MODAL_DEPOSIT, router)}>
                                                    <a
                                                        onClick={handleCloseNotification}
                                                        className="btn btn-modern btn-secondary"
                                                    >
                                                        {tr['deposit.no_methods_available.try_again']}
                                                    </a>
                                                </Link>
                                                <button
                                                    type="button"
                                                    className="notification--close"
                                                    onClick={handleCloseNotification}
                                                    data-testid="close_notification"
                                                >
                                                    <Cross />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </NotificationContent>
                            )}
                        </Notification>
                        <ToastNotification
                            isOpen={uploadStatus.isSuccess}
                            status={NOTIFICATIONS_STATUS_SUCCESS}
                            onClose={onCloseDocNotification}
                            picto={<Success />}
                            message={tr['document_upload_success']}
                        />
                        <ToastNotification
                            isOpen={uploadStatus.isError}
                            status={NOTIFICATIONS_STATUS_ERROR}
                            onClose={onCloseDocNotification}
                            picto={<AlertTriangle />}
                            message={uploadStatus.error}
                        />
                        <ToastNotification
                            isOpen={updateBankCard.isSuccess}
                            status={NOTIFICATIONS_STATUS_SUCCESS}
                            onClose={onCloseBankCardNotification}
                            picto={<Success />}
                            message={tr['profile.my_bank_cards.set_default.success']}
                        />
                        <ToastNotification
                            isOpen={updateBankCard.isError}
                            status={NOTIFICATIONS_STATUS_ERROR}
                            onClose={onCloseBankCardNotification}
                            picto={<AlertTriangle />}
                            message={tr['profile.my_bank_cards.set_default.error']}
                        />
                        <ToastNotification
                            isOpen={claimBonusSuccess}
                            status={NOTIFICATIONS_STATUS_SUCCESS}
                            onClose={onCloseClaimBonusNotification}
                            picto={<Success />}
                            message={claimBonusMessage}
                        />
                        <ToastNotification
                            isOpen={claimBonusFailed}
                            status={NOTIFICATIONS_STATUS_ERROR}
                            onClose={onCloseClaimBonusNotification}
                            picto={<AlertTriangle />}
                            message={claimBonusMessage}
                        />
                        <FavoritePanelNotification
                            isOpen={Boolean(favoriteGameMethod)}
                            status={NOTIFICATIONS_STATUS_SUCCESS}
                            onClose={onCloseFavoriteNotification}
                            picto={<Success />}
                        />
                    </Fragment>
                )}
            </Fragment>
        );
    };

    return WithNotifications;
};
