import dayjs from 'dayjs';

export const getWheelTimer = (milliseconds) => {
    const durationObj = dayjs.duration(milliseconds);
    const totalHours = durationObj.days() * 24 + durationObj.hours();
    const time = [
        durationObj.minutes(),
        durationObj.seconds()
    ];
    const totalTime = totalHours > 0 ? [totalHours, ...time] : [...time];
    const totalTimeFormatted = totalTime.map(item => String(item).padStart(2, '0'));

    return totalTimeFormatted.join(':');
};
