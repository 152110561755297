import { COOKIE_DOMAIN_LOCAL, COOKIE_DOMAIN_PRODUCTION, COOKIE_DOMAIN_STAGING } from '../variables';

const getCookieDomain = (hostname = window.location.hostname) => {
    if (hostname.includes(COOKIE_DOMAIN_STAGING)) {
        return COOKIE_DOMAIN_STAGING;
    } else if (hostname.includes(COOKIE_DOMAIN_PRODUCTION)) {
        return COOKIE_DOMAIN_PRODUCTION;
    } else {
        return COOKIE_DOMAIN_LOCAL;
    }
};

export default getCookieDomain;
