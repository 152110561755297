import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import AnimatedStatus from '../../AnimatedStatus';
import { mfaStatusInit, setMFAActiveStep, setMFAEnabled, setMFAModal } from '../../../../store/reducers/user';

const MfaStatusContent = ({ status, title, content, loginStatus = false, isSuccess }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(setMFAModal(false));
        dispatch(mfaStatusInit());
        dispatch(setMFAActiveStep(1));
        loginStatus && dispatch(setMFAEnabled(false));
    };

    return (
        <div className={cx('mfa-modal--content', { 'status-modal': isSuccess })}>
            <div className="mfa-modal--content-status">
                {status && <AnimatedStatus status={status}/>}
                <div className="status-wrapper">
                    <h2>{title}</h2>
                    <span dangerouslySetInnerHTML={{ __html: content }}/>
                </div>
                <div
                    className={cx({
                        'mfa-modal--content-footer': !loginStatus,
                        'mfa-modal--content-footer--login': loginStatus
                    })}
                >
                    <button
                        type="submit"
                        className="btn btn-submit btn-modern"
                        onClick={onClose}
                        data-testid="mfa_status_modal_btn"
                    >
                        {tr['draggable_widget_okay']}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MfaStatusContent;
