export const birthFields = [
    {
        name: 'birthDay',
        placeholder: 'DD',
        maxLength: 2
    },
    {
        name: 'birthMonth',
        placeholder: 'MM',
        maxLength: 2
    },
    {
        name: 'birthYear',
        placeholder: 'YYYY',
        maxLength: 4
    }
];
