const RecentlyPlayedGames = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M11.9945 24C5.38102 24 0 18.6165 0 12C0 5.38349 5.38102 0 11.9945 0C14.5695 0 16.9243 0.53945 19.8074 3.06055V2.25688C19.8074 1.70642 20.2476 1.26606 20.7978 1.26606C21.348 1.26606 21.7882 1.70642 21.7882 2.25688V5.27339C21.8432 5.82385 21.458 6.30826 20.9188 6.37431H20.8088C20.8088 6.37431 20.7318 6.37431 20.6878 6.37431H17.6616C17.1114 6.37431 16.6713 5.93395 16.6713 5.38349C16.6713 4.83303 17.1114 4.39266 17.6616 4.39266H18.3329C15.978 2.4 14.1293 1.97064 11.9945 1.97064C6.47043 1.97064 1.98074 6.46239 1.98074 11.989C1.98074 17.5156 6.47043 22.0073 11.9945 22.0073C17.5186 22.0073 21.9972 17.5156 22.0083 12C22.0083 10.9211 21.8432 9.85321 21.5021 8.84037C21.337 8.31193 21.6121 7.77248 22.1293 7.59633C22.2393 7.5633 22.3384 7.54129 22.4484 7.54129C22.8776 7.54129 23.2517 7.81651 23.3948 8.22385C23.8019 9.44587 24 10.7119 24 12C24 18.6165 18.619 24 12.0055 24H11.9945ZM15.2957 16.2936C15.0316 16.2936 14.7675 16.1835 14.5915 15.9853L11.0041 12.4073V5.38349C11.0041 4.83303 11.4443 4.39266 11.9945 4.39266C12.5447 4.39266 12.9849 4.83303 12.9849 5.38349V11.5706L15.989 14.5872C16.1981 14.8073 16.2971 15.0495 16.2861 15.3138C16.2861 15.578 16.1651 15.8092 15.978 15.9853C15.7689 16.1725 15.5378 16.2716 15.2957 16.2716V16.2936Z"
            fill="black"
        />
    </svg>
);

export default RecentlyPlayedGames;
