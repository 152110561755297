const MysteryChests = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
        <path
            d="M26.13 0H3.87C1.73 0 0 1.66083 0 3.70185V19.1596C0 19.6198 0.39 20 0.88 20H29.12C29.61 20 30 19.6198 30 19.1596V3.70185C30 1.66083 28.27 0 26.13 0ZM28.24 3.70185V10.4352H19.57V7.4037H24.44C24.93 7.4037 25.32 7.02351 25.32 6.56328V1.68084H26.13C27.29 1.68084 28.24 2.58129 28.24 3.70185ZM10.64 12.1261C11.11 13.3867 12.35 14.2971 13.83 14.2971H16.17C17.65 14.2971 18.89 13.3867 19.36 12.1261H23.56V18.3292H6.44V12.1261H10.64ZM6.44 1.71086H23.56V5.72286H18.76C18.76 5.72286 18.71 5.71286 18.69 5.71286H11.31C11.31 5.71286 11.26 5.72286 11.24 5.72286H6.44V1.71086ZM17.81 7.4037V11.0355C17.81 11.906 17.08 12.6063 16.17 12.6063H13.83C12.92 12.6063 12.19 11.906 12.19 11.0355V7.4037H17.81ZM3.87 1.68084H4.68V6.56328C4.68 7.02351 5.07 7.4037 5.56 7.4037H10.43V10.4352H1.76V3.70185C1.76 2.58129 2.7 1.68084 3.87 1.68084ZM1.76 12.1261H4.68V18.3292H1.76V12.1261ZM25.32 18.3292V12.1261H28.24V18.3292H25.32Z"
            fillOpacity="0.5"
            fill="white"
        />
        <path
            d="M14.12 10.8454H15.88C16.37 10.8454 16.76 10.4652 16.76 10.005C16.76 9.54477 16.37 9.16458 15.88 9.16458H14.12C13.63 9.16458 13.24 9.54477 13.24 10.005C13.24 10.4652 13.63 10.8454 14.12 10.8454Z"
            fillOpacity="0.5"
            fill="white"
        />
    </svg>
);

export default MysteryChests;
