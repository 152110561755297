const HelpCenter = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <g opacity="0.5">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11 1.81176C5.92548 1.81176 1.81176 5.92548 1.81176 11C1.81176 16.0745 5.92548 20.1882 11 20.1882C16.0745 20.1882 20.1882 16.0745 20.1882 11C20.1882 5.92548 16.0745 1.81176 11 1.81176ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
                  fill="white" />
            <path
                d="M11.5869 5.71977C11.0033 5.60367 10.3983 5.66326 9.84853 5.89099C9.29873 6.11872 8.82882 6.50437 8.49821 6.99917C8.16759 7.49396 7.99113 8.07569 7.99113 8.67078C7.99113 9.04601 8.29531 9.35019 8.67054 9.35019C9.04577 9.35019 9.34995 9.04601 9.34995 8.67078C9.34995 8.34444 9.44672 8.02543 9.62803 7.75409C9.80933 7.48275 10.067 7.27126 10.3685 7.14638C10.67 7.02149 11.0018 6.98882 11.3219 7.05248C11.6419 7.11615 11.9359 7.2733 12.1667 7.50405C12.3974 7.73481 12.5546 8.02881 12.6182 8.34888C12.6819 8.66895 12.6492 9.00071 12.5244 9.30221C12.3995 9.6037 12.188 9.8614 11.9166 10.0427C11.6453 10.224 11.3263 10.3208 11 10.3208C10.6247 10.3208 10.3205 10.625 10.3205 11.0002V13.459C10.3205 13.8342 10.6247 14.1384 11 14.1384C11.3752 14.1384 11.6794 13.8342 11.6794 13.459V11.6019C12.0315 11.5203 12.3679 11.3754 12.6716 11.1725C13.1664 10.8419 13.552 10.372 13.7797 9.82221C14.0075 9.27242 14.0671 8.66744 13.951 8.08379C13.8349 7.50013 13.5483 6.96401 13.1275 6.54322C12.7067 6.12243 12.1706 5.83587 11.5869 5.71977Z"
                fill="white" />
            <path
                d="M11 14.9798C10.4103 14.9798 9.93231 15.4578 9.93231 16.0474C9.93231 16.6371 10.4103 17.1151 11 17.1151C11.5896 17.1151 12.0676 16.6371 12.0676 16.0474C12.0676 15.4578 11.5896 14.9798 11 14.9798Z"
                fill="white" />
        </g>
    </svg>
);

export default HelpCenter;
