import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import StatusModal from '../../StatusModal';
import {
    LOGIN_ATTEMPTS_REACHED,
    MENU_FORGOT_PASSWORD,
    MODAL_STATUS_WARNING,
    TOKEN_EXPIRED,
    TOKEN_INVALID
} from '../../../../variables';
import { getMenuUrl } from '../../../../utils/locationConfig';
import Link from 'next/link';
import { initSubmitNewPassword } from '../../../../store/reducers/user';
import { openCustomerSupportWidget } from '@tlf-e/brand-utils';

const NewPasswordExpiredLinkModal = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const { submitNewPassword } = useSelector((state) => state.user);
    const { isRegistrationAllowed } = useSelector((state) => state.register);
    const activeError = submitNewPassword?.errors[0]?.detail.error || '';
    const isTokenExpired = activeError === TOKEN_EXPIRED || activeError === TOKEN_INVALID;
    const router = useRouter();
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(initSubmitNewPassword());
    };

    const handleContactSupport = () => {
        openCustomerSupportWidget();
        onClose();
    };
    const errorTypeMap = {
        [TOKEN_EXPIRED]: {
            title: tr.new_password_link_expired,
            content: tr.new_password_link_expired_content
        },
        [TOKEN_INVALID]: {
            title: '',
            content: tr.new_password_link_invalid_content
        },
        [LOGIN_ATTEMPTS_REACHED]: {
            title: tr.new_password_unable_change,
            content: tr.new_password_unable_change_content
        }
    };

    const modalTitle = errorTypeMap[activeError]?.title || '';
    const failedModalContent = errorTypeMap[activeError]?.content ?? tr.new_password_invalid_password_content;

    return (
        <StatusModal
            isOpen={submitNewPassword.isError}
            status={MODAL_STATUS_WARNING}
            title={modalTitle}
            onClose={onClose}
            content={
                <div className="wdr-success-content">
                    <div>{failedModalContent}</div>
                </div>
            }
        >
            <div className="container-btns">
                <button className="btn btn-cancel btn-modern" onClick={onClose} data-testid="close_btn">
                    {tr['btn.cancel']}
                </button>
                {isRegistrationAllowed && isTokenExpired ? (
                    <Link {...getMenuUrl(MENU_FORGOT_PASSWORD, router)}>
                        <a
                            className="btn btn-submit btn-large btn-modern"
                            data-testid="request_password_btn"
                            onClick={onClose}
                        >
                            {tr['new_password_request_pass_btn']}
                        </a>
                    </Link>
                ) : (
                    <button
                        className="btn btn-submit btn-large btn-modern"
                        target="_top"
                        onClick={handleContactSupport}
                        data-testid="open-support-widget"
                    >
                        {tr.contact_support}
                    </button>
                )}
            </div>
        </StatusModal>
    );
};

export default NewPasswordExpiredLinkModal;
