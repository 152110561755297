const SET_VIP_LEVELS_MODAL = 'SET_VIP_LEVELS_MODAL';
const SET_VIP_LEVEL_DETAILS = 'SET_VIP_LEVEL_DETAILS';
const SET_VIP_LEVELS_COMPARED = 'SET_VIP_LEVELS_COMPARED';

export const initialState = {
    vipLevelsModal: {
        view: '',
        closeReason: ''
    },
    vipLevelDetails: '',
    vipLevelsCompared: []
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case SET_VIP_LEVELS_MODAL:
        return {
            ...state,
            vipLevelsModal: action.payload
        };
    case SET_VIP_LEVEL_DETAILS:
        return {
            ...state,
            vipLevelDetails: action.payload
        };
    case SET_VIP_LEVELS_COMPARED:
        return {
            ...state,
            vipLevelsCompared: action.payload,
        };
    default:
        return state;
    }
};

export const setVipLevelsModal = (payload) => ({
    type: SET_VIP_LEVELS_MODAL,
    payload
});

export const setVipLevelDetails = (payload) => ({
    type: SET_VIP_LEVEL_DETAILS,
    payload
});

export const setVipLevelsCompared = (payload) => ({
    type: SET_VIP_LEVELS_COMPARED,
    payload
});
