import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { BackgroundAnimation } from '@tlf-e/react-components';
import Fade from '@mui/material/Fade';
import WheelWidgetCountdown from './WheelWidgetCountdown';
import routes from '../../../../../utils/routes.json';
import { useWidgetTimer } from '../../../../../hooks/useWidgetTimer';
import { imageBaseUrl } from '../../../../../services/http';
import { useSSRContext } from '../../../../../hooks/useSSRContext';

const WheelWidgetIcon = ({ isOpen, onOpen, isMobile }) => {
    const { timeToSpinLoaded } = useSelector((state) => state.wheel_of_fortune);
    const { firstDepositOfferBubbleVisible } = useSelector((state) => state.bank);
    const router = useRouter();
    const { time, countdownPercentage } = useWidgetTimer();
    const { isGamePage } = useSSRContext();
    const fadeIn = `${router.pathname}/` !== routes.wheel_of_fortune && !isOpen && timeToSpinLoaded && !isGamePage;

    return (
        <Fade in={fadeIn} timeout={500}>
            <div
                onClick={onOpen}
                data-testid="open_wheel_modal"
                className={cx('wheel-widget', {
                    active: countdownPercentage > 0,
                    'with-ftd': firstDepositOfferBubbleVisible
                })}
            >
                {countdownPercentage > 0 ? (
                    <WheelWidgetCountdown time={time} countdownPercentage={countdownPercentage} size={isMobile ? 70 : 80} />
                ) : (
                    <Fragment>
                        <BackgroundAnimation size={isMobile ? 40 : 50} multiplier={3.75} adjustment={0} />
                        <img
                            className="animated"
                            src={`${imageBaseUrl}/wheel-of-fortune/wheel_widget_color.png`}
                            alt="widget_image"
                        />
                    </Fragment>
                )}
            </div>
        </Fade>
    );
};

export default WheelWidgetIcon;
