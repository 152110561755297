import AxiosInitializer from '@tlf-e/brand-utils/dist/services/AxiosInitializer';
import BankWorkers from '@tlf-e/brand-utils/dist/services/BankWorkers';
import BonusWorkers from '@tlf-e/brand-utils/dist/services/BonusWorkers';
import GamesWorkers from '@tlf-e/brand-utils/dist/services/GamesWorkers';
import GlobalWorkers from '@tlf-e/brand-utils/dist/services/GlobalWorkers';
import PromotionsWorkers from '@tlf-e/brand-utils/dist/services/PromotionsWorkers';
import RegisterWorkers from '@tlf-e/brand-utils/dist/services/RegisterWorkers';
import SliderWorkers from '@tlf-e/brand-utils/dist/services/SliderWorkers';
import UserWorkers from '@tlf-e/brand-utils/dist/services/UserWorkers';
import WheelOfFortuneWorkers from '@tlf-e/brand-utils/dist/services/WheelOfFortuneWorkers';
import MysteryChestsWorkers from '@tlf-e/brand-utils/dist/services/MysteryChestsWorkers';
import WorkerHelper from '@tlf-e/brand-utils/dist/services/WorkerHelper';
import { datadogAddError } from '../utils/datadogInit';
import { COOKIE_NAME_TOKEN } from '../variables';
import { includeRouterContext } from '../utils/locationConfig';
import { handleUnauthorizedError, setStoreTokenTime } from './helper';

export const AppStore = {
    store: {}
};

export const apiUrl = process.env.NEXT_PUBLIC_API_URL;
export const baseUrl = `${apiUrl}v2/`;
export const socketUrl = process.env.NEXT_PUBLIC_SOCKET_URL;
export const imageBaseUrl = 'https://assets.wild-endpoint.com/v2/images';

export const axiosInitializerInstance = new AxiosInitializer({
    cookieNameToken: COOKIE_NAME_TOKEN,
    baseUrl,
    xPlayerRealIp: '',
    setStoreTokenTime,
    handleUnauthorizedError,
    datadogAddError,
    includeRouterContext
});

export const http = axiosInitializerInstance.http;

// Initializing workers to use them across entire app
export const bankWorkers = new BankWorkers(http);
export const bonusWorkers = new BonusWorkers(http);
export const gamesWorkers = new GamesWorkers(http);
export const globalWorkers = new GlobalWorkers(http);
export const promotionsWorkers = new PromotionsWorkers(http);
export const registerWorkers = new RegisterWorkers(http);
export const sliderWorkers = new SliderWorkers(http);
export const userWorkers = new UserWorkers(http);
export const wheelOfFortuneWorkers = new WheelOfFortuneWorkers(http);
export const mysteryChestsWorkers = new MysteryChestsWorkers(http);
export const {
    getLocaleCountryCapitalized,
    getShortenedLocale
} = new WorkerHelper();
