import {
    COUNTRY_CODE_BL,
    COUNTRY_CODE_CA,
    COUNTRY_CODE_CH,
    COUNTRY_CODE_FI,
    COUNTRY_CODE_FR,
    COUNTRY_CODE_GF,
    COUNTRY_CODE_GP,
    COUNTRY_CODE_IN,
    COUNTRY_CODE_IS,
    COUNTRY_CODE_JP,
    COUNTRY_CODE_LU,
    COUNTRY_CODE_MF,
    COUNTRY_CODE_MQ,
    COUNTRY_CODE_MX,
    COUNTRY_CODE_NC,
    COUNTRY_CODE_NO,
    COUNTRY_CODE_PF,
    COUNTRY_CODE_PM,
    COUNTRY_CODE_RE,
    COUNTRY_CODE_TF,
    COUNTRY_CODE_WF,
    COUNTRY_CODE_YT,
    LANGUAGE_AR_AE,
    LANGUAGE_EN,
    LANGUAGE_EN_CA,
    LANGUAGE_ES_MX,
    LANGUAGE_FI,
    LANGUAGE_FI_FI,
    LANGUAGE_FR,
    LANGUAGE_FR_CA,
    LANGUAGE_JA_JP,
    LANGUAGE_NN_NO,
    LANGUAGE_PT_BR,
    LANGUAGE_RU_RU,
    LANGUAGE_TH_TH,
    LANGUAGE_TR_TR,
    LANGUAGE_VI_VN,
    LANGUAGE_ZN_CN
} from '../variables';

export const getAllowedLocales = (locale) => {
    switch (locale) {
    case COUNTRY_CODE_FR:
    case COUNTRY_CODE_CH:
    case COUNTRY_CODE_BL:
    case COUNTRY_CODE_GP:
    case COUNTRY_CODE_MF:
    case COUNTRY_CODE_MQ:
    case COUNTRY_CODE_NC:
    case COUNTRY_CODE_PF:
    case COUNTRY_CODE_PM:
    case COUNTRY_CODE_RE:
    case COUNTRY_CODE_TF:
    case COUNTRY_CODE_WF:
    case COUNTRY_CODE_GF:
    case COUNTRY_CODE_YT:
        return [
            LANGUAGE_FR,
            LANGUAGE_EN,
            LANGUAGE_PT_BR,
            LANGUAGE_ES_MX,
            LANGUAGE_TR_TR,
            LANGUAGE_FI_FI,
            LANGUAGE_RU_RU,
            LANGUAGE_VI_VN,
            LANGUAGE_TH_TH,
            LANGUAGE_NN_NO,
            LANGUAGE_ZN_CN,
            LANGUAGE_AR_AE,
            LANGUAGE_JA_JP
        ];
    case COUNTRY_CODE_CA:
        return [LANGUAGE_FR_CA, LANGUAGE_EN_CA];
    case COUNTRY_CODE_FI:
        return [LANGUAGE_EN, LANGUAGE_FI];
    case COUNTRY_CODE_LU:
        return [LANGUAGE_FR];
    case COUNTRY_CODE_JP:
        return [LANGUAGE_EN, LANGUAGE_JA_JP];
    case COUNTRY_CODE_IS:
    case COUNTRY_CODE_IN:
    case COUNTRY_CODE_MX:
    case COUNTRY_CODE_NO:
    default:
        return [LANGUAGE_EN];
    }
};
