import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getWheelTimer } from '../utils/wheelOfFortuneHelper';

const updateCountdown = (time, fullTime) => ({
    countdown: time,
    countdownPercentage: Math.ceil((time / fullTime) * 100)
});

export const useWidgetTimer = () => {
    const { timeToSpin, wof } = useSelector((state) => state.wheel_of_fortune);
    const [state, setState] = useState(updateCountdown(0, 0));
    const timeToSpinTimestamp = timeToSpin * 1000;

    useEffect(() => {
        if (timeToSpinTimestamp > 0 && wof) {
            const timerBetweenSpins = (wof.timerBetweenSpins || 0) * 60 * 1000;
            setState(updateCountdown(timeToSpinTimestamp, timerBetweenSpins));
            const interval = setInterval(
                () =>
                    setState((state) => {
                        const time = state.countdown - 1000;
                        if (time === 0) {
                            clearInterval(interval);
                        }
                        return updateCountdown(time, timerBetweenSpins);
                    }),
                1000
            );
            return () => clearInterval(interval);
        }
    }, [timeToSpinTimestamp, wof]);

    return {
        time: getWheelTimer(state.countdown),
        countdownPercentage: state.countdownPercentage
    };
};
