import cx from 'classnames';
import { Field, useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask/dist/reactTextMask';
import { dataLayerPush, dayMaskBuilder, monthMaskBuilder, yearMaskBuilder, onInputNumber } from '@tlf-e/brand-utils';
import FormErrorMessage from '../../FormErrorMessage';

const maskBuilders = {
    birthDay: dayMaskBuilder,
    birthMonth: monthMaskBuilder,
    birthYear: yearMaskBuilder
};

const fieldValues = {
    birthDay: 'birthdate_day',
    birthMonth: 'birthdate_month',
    birthYear: 'birthdate_year'
};

const BirthField = ({ name, ...fieldProps }) => {
    const { touched, errors } = useFormikContext();
    const maskBuilder = maskBuilders[name];

    return (
        <div
            className={cx('form-group--cell', {
                'form-group--cell-error-marked': touched[name] && errors[name]
            })}
        >
            <Field name={name}>
                {({ field }) => (
                    <MaskedInput
                        {...field}
                        {...fieldProps}
                        mask={maskBuilder(field.value)}
                        guide={false}
                        type="text"
                        inputMode="numeric"
                        id={`register_${name}`}
                        data-testid={`register_${name}`}
                        autoComplete="off"
                        autoCapitalize="off"
                        className="text-field"
                        onInput={onInputNumber}
                        onBlur={(e) => {
                            if (e.target.value) {
                                dataLayerPush({
                                    event: 'signup_filled_field',
                                    field: fieldValues[name]
                                });
                            }
                        }}
                    />
                )}
            </Field>
            <FormErrorMessage name={name} />
        </div>
    );
};

export default BirthField;
