import { Fragment, lazy } from 'react';
import { useSelector } from 'react-redux';

const MysteryChestsSetup = lazy(() => import('../common/mystery-chests/MysteryChestsSetup'));

export const withMysteryChestsSetup = (PageComponent) => {
    const WithMysteryChestsSetup = ({ ...props }) => {
        const { loadingProgress } = useSelector((state) => state.global);
        const { id: userId } = useSelector((state) => state.user.information);
        const { isMysteryChestsEnabled } = useSelector((state) => state.mystery_chests);
        const mysteryChestsEnabled = loadingProgress === 100 && userId && isMysteryChestsEnabled;

        return (
            <Fragment>
                <PageComponent {...props} />
                {mysteryChestsEnabled && <MysteryChestsSetup />}
            </Fragment>
        );
    };

    return WithMysteryChestsSetup;
};
