import { mysteryChestsWorkers } from '../../services/http';
import {
    FREE_ROUNDS,
    MYSTERY_CHESTS_STATUS_DISABLED,
    MYSTERY_CHESTS_STATUS_OPENED
} from '../../variables';
import dayjs from 'dayjs';
import { setGameRouterHistory } from './games';
import { getGamePath } from '../../utils/locationConfig';
import router from 'next/router';

const {
    getMysteryChestsPrizeRequest,
    getMysteryChestsStatusRequest,
    claimMysteryChestsPrizeRequest
} = mysteryChestsWorkers;

const SET_MYSTERY_CHESTS_MODAL_OPENED = 'SET_MYSTERY_CHESTS_MODAL_OPENED';
const INIT_MYSTERY_CHESTS_STATE = 'INIT_MYSTERY_CHESTS_STATE';
const GET_MYSTERY_CHESTS_PRIZE = 'GET_MYSTERY_CHESTS_PRIZE';
const GET_MYSTERY_CHESTS_PRIZE_SUCCESS = 'GET_MYSTERY_CHESTS_PRIZE_SUCCESS';
const GET_MYSTERY_CHESTS_PRIZE_FAILED = 'GET_MYSTERY_CHESTS_PRIZE_FAILED';
const INIT_MYSTERY_CHESTS_PRIZE = 'INIT_MYSTERY_CHESTS_PRIZE';
const GET_MYSTERY_CHESTS_STATUS = 'GET_MYSTERY_CHESTS_STATUS';
const GET_MYSTERY_CHESTS_STATUS_SUCCESS = 'GET_MYSTERY_CHESTS_STATUS_SUCCESS';
const GET_MYSTERY_CHESTS_STATUS_FAILED = 'GET_MYSTERY_CHESTS_STATUS_FAILED';
const CLAIM_MYSTERY_CHESTS_PRIZE = 'CLAIM_MYSTERY_CHESTS_PRIZE';
const CLAIM_MYSTERY_CHESTS_PRIZE_SUCCESS = 'CLAIM_MYSTERY_CHESTS_PRIZE_SUCCESS';
const CLAIM_MYSTERY_CHESTS_PRIZE_FAILED = 'CLAIM_MYSTERY_CHESTS_PRIZE_FAILED';

const getTimeParams = () => ({
    timezoneOffset: new Date().getTimezoneOffset()
});

export const getCurrentDate = () => dayjs().get('date');

export const initialState = {
    isMysteryChestsModalOpened: false,
    mysteryChestsStatus: '',
    mysteryChestsStatusLoading: false,
    isMysteryChestsEnabled: false,
    timeToEndOfDay: null,
    currentDate: null,
    mysteryChestsPrize: null,
    mysteryChestsPrizeLoading: false,
    claimMysteryChestsPrizeLoading: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case SET_MYSTERY_CHESTS_MODAL_OPENED:
        return {
            ...state,
            isMysteryChestsModalOpened: action.payload
        };
    case GET_MYSTERY_CHESTS_STATUS:
        return {
            ...state,
            mysteryChestsStatusLoading: true
        };
    case GET_MYSTERY_CHESTS_STATUS_SUCCESS:
        return {
            ...state,
            mysteryChestsStatus: action.payload,
            mysteryChestsStatusLoading: false,
            isMysteryChestsEnabled: action.payload !== MYSTERY_CHESTS_STATUS_DISABLED,
            timeToEndOfDay: action.timeToEndOfDay,
            currentDate: getCurrentDate()
        };
    case GET_MYSTERY_CHESTS_STATUS_FAILED:
        return {
            ...state,
            mysteryChestsStatus: MYSTERY_CHESTS_STATUS_DISABLED,
            mysteryChestsStatusLoading: false,
            isMysteryChestsEnabled: false
        };
    case GET_MYSTERY_CHESTS_PRIZE:
        return {
            ...state,
            mysteryChestsPrizeLoading: true,
        };
    case GET_MYSTERY_CHESTS_PRIZE_SUCCESS:
        return {
            ...state,
            mysteryChestsPrize: action.payload,
            mysteryChestsPrizeLoading: false,
            timeToEndOfDay: action.timeToEndOfDay,
            currentDate: getCurrentDate()
        };
    case GET_MYSTERY_CHESTS_PRIZE_FAILED:
        return {
            ...state,
            mysteryChestsPrizeLoading: false
        };
    case INIT_MYSTERY_CHESTS_PRIZE:
        return {
            ...state,
            mysteryChestsPrize: null,
            mysteryChestsPrizeLoading: false
        };
    case CLAIM_MYSTERY_CHESTS_PRIZE:
        return {
            ...state,
            claimMysteryChestsPrizeLoading: true
        };
    case CLAIM_MYSTERY_CHESTS_PRIZE_SUCCESS:
        return {
            ...state,
            claimMysteryChestsPrizeLoading: false
        };
    case CLAIM_MYSTERY_CHESTS_PRIZE_FAILED:
        return {
            ...state,
            claimMysteryChestsPrizeLoading: false
        };
    case INIT_MYSTERY_CHESTS_STATE:
        return initialState;
    default:
        return state;
    }
};

export const setMysteryChestsModalOpened = (payload) => ({
    type: SET_MYSTERY_CHESTS_MODAL_OPENED,
    payload
});

export const initMysteryChestsState = () => ({
    type: INIT_MYSTERY_CHESTS_STATE
});

export const getMysteryChestsStatus = () => {
    const loading = () => ({
        type: GET_MYSTERY_CHESTS_STATUS
    });
    const success = (payload, timeToEndOfDay) => ({
        type: GET_MYSTERY_CHESTS_STATUS_SUCCESS,
        payload,
        timeToEndOfDay
    });
    const fail = () => ({
        type: GET_MYSTERY_CHESTS_STATUS_FAILED
    });

    return (dispatch, getState) => {
        dispatch(initMysteryChestsPrize());
        dispatch(loading());
        getMysteryChestsStatusRequest(getTimeParams())
            .then((res) => {
                const { mysteryChestsPrize } = getState().mystery_chests;
                const { timeToEndOfDay, status } = res.data;
                const setStatus = (time) => dispatch(success(status, time));

                // Set opened status only with prize to handle mystery chests modal properly
                if (status === MYSTERY_CHESTS_STATUS_OPENED && !mysteryChestsPrize) {
                    return dispatch(getMysteryChestsPrize(setStatus));
                }

                setStatus(timeToEndOfDay);
            })
            .catch(() => dispatch(fail()));
    };
};

export const getMysteryChestsPrize = (setStatus) => {
    const loading = () => ({
        type: GET_MYSTERY_CHESTS_PRIZE
    });
    const success = (payload, timeToEndOfDay) => ({
        type: GET_MYSTERY_CHESTS_PRIZE_SUCCESS,
        payload,
        timeToEndOfDay
    });
    const fail = () => ({
        type: GET_MYSTERY_CHESTS_PRIZE_FAILED
    });

    return (dispatch) => {
        dispatch(loading());
        getMysteryChestsPrizeRequest(getTimeParams())
            .then((res) => {
                const { timeToEndOfDay, data } = res.data;
                setStatus && setStatus(timeToEndOfDay);
                dispatch(success(data, timeToEndOfDay));
            })
            .catch(() => dispatch(fail()));
    };
};

export const initMysteryChestsPrize = () => ({
    type: INIT_MYSTERY_CHESTS_PRIZE
});

export const claimMysteryChestsPrize = (prizeId, onClose, handleClaim) => {
    const loading = () => ({
        type: CLAIM_MYSTERY_CHESTS_PRIZE
    });
    const success = () => ({
        type: CLAIM_MYSTERY_CHESTS_PRIZE_SUCCESS
    });
    const fail = () => ({
        type: CLAIM_MYSTERY_CHESTS_PRIZE_FAILED
    });

    return (dispatch, getState) => {
        dispatch(loading());
        claimMysteryChestsPrizeRequest({
            ...getTimeParams(),
            prizeId
        })
            .then((res) => {
                const { id: bonusId } = res.data;
                const processClaiming = () => {
                    onClose({ isStatusCallPrevented: true });
                    dispatch(getMysteryChestsStatus());
                };

                handleClaim(bonusId, {
                    onSuccess: (bonus) => {
                        processClaiming();
                        dispatch(success());
                        // Game Redirect for free rounds type
                        if (bonus && bonus.type === FREE_ROUNDS && Array.isArray(bonus.freeRounds.gameId)) {
                            const gamesData = getState().games.data;
                            const game = gamesData.games.find((game) => game.id === bonus.freeRounds.gameId[0]);
                            dispatch(setGameRouterHistory(router));
                            const path = getGamePath(game, gamesData, router.locale);
                            router.push(path);
                        }
                    },
                    onError: () => {
                        processClaiming();
                        dispatch(fail());
                    }
                });
            })
            .catch(() => dispatch(fail()));
    };
};
