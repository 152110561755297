import { promotionsWorkers } from '../../services/http';
import { getPromotionData, sanitizePromoData, setPromoStatus } from '../../utils/promotions';
import { findPromoGamesList } from '@tlf-e/brand-utils';
import { PROMO_STATUS_UPCOMING, PROMO_STATUS_ONGOING, PROMO_KEY_ALL } from '../../variables';

const { promotionsRequest, getPromotionRequest, enrollPromotionRequest } = promotionsWorkers;

const GET_PROMOTIONS = 'GET_PROMOTIONS';
const GET_PROMOTIONS_ERROR = 'GET_PROMOTIONS_ERROR';
const GET_PROMOTIONS_LOADING = 'GET_PROMOTIONS_LOADING';
const SET_PROMOTION_TO_BE_UPDATED = 'SET_PROMOTION_TO_BE_UPDATED';
const SET_PROMO_ACTIVE_TAB = 'SET_PROMO_ACTIVE_TAB';
const ENROLL_PROMOTION = 'ENROLL_PROMOTION';
const ENROLL_PROMOTION_SUCCESS = 'ENROLL_PROMOTION_SUCCESS';
const ENROLL_PROMOTION_FAILED = 'ENROLL_PROMOTION_FAILED';
const GET_CURRENT_PROMO_ENROLL = 'GET_CURRENT_PROMO_ENROLL';
const GET_CURRENT_PROMO_ENROLL_LOADING = 'GET_CURRENT_PROMO_ENROLL_LOADING';
const GET_CURRENT_PROMO_ENROLL_FAILED = 'GET_CURRENT_PROMO_ENROLL_FAILED';
const SET_IS_COMMUNICATION_PREFERENCES_MODAL_OPENED = 'SET_IS_COMMUNICATION_PREFERENCES_MODAL_OPENED';

export const initialState = {
    isLoading: false,
    isLoaded: false,
    allPromotions: [],
    isError: false,
    promotionToBeUpdated: null,
    promotionsData: {
        [PROMO_KEY_ALL]: [],
        [PROMO_STATUS_ONGOING]: [],
        [PROMO_STATUS_UPCOMING]: []
    },
    activeTab: PROMO_KEY_ALL,
    isPromoEnrolled: false,
    enrollPromotion: {
        isLoading: false,
        isLoaded: false
    },
    isCommunicationPreferencesModalOpened: false
};

const promotions = (state = initialState, action = {}) => {
    switch (action.type) {
    case GET_PROMOTIONS:
        return {
            ...state,
            allPromotions: action.payload,
            promotionsData: getPromotionData(action.payload),
            isLoading: false,
            isError: false,
            isLoaded: true
        };
    case GET_PROMOTIONS_ERROR:
        return {
            ...state,
            isLoading: false,
            isError: true,
            isLoaded: true
        };
    case GET_PROMOTIONS_LOADING:
        return {
            ...state,
            isLoading: true,
            isError: false
        };
    case SET_PROMOTION_TO_BE_UPDATED:
        return {
            ...state,
            promotionToBeUpdated: action.payload
        };
    case SET_PROMO_ACTIVE_TAB:
        return {
            ...state,
            activeTab: action.payload
        };
    case GET_CURRENT_PROMO_ENROLL_LOADING:
        return {
            ...state,
            enrollPromotion: {
                isLoading: true,
                isLoaded: false
            },
        };
    case GET_CURRENT_PROMO_ENROLL:
        return {
            ...state,
            isPromoEnrolled: action.payload.isPromoEnrolled,
            enrollPromotion: {
                isLoading: false,
                isLoaded: true
            },
        };
    case GET_CURRENT_PROMO_ENROLL_FAILED:
        return {
            ...state,
            enrollPromotion: {
                isLoading: false,
                isLoaded: true
            },
        };
    case ENROLL_PROMOTION:
        return {
            ...state,
            enrollPromotion: {
                ...state.enrollPromotion,
                isLoading: true
            },
        };
    case ENROLL_PROMOTION_SUCCESS:
        return {
            ...state,
            enrollPromotion: {
                isLoading: false,
                isLoaded: true
            },
        };
    case ENROLL_PROMOTION_FAILED:
        return {
            ...state,
            enrollPromotion: {
                isLoading: false,
                isLoaded: true
            },
        };
    case SET_IS_COMMUNICATION_PREFERENCES_MODAL_OPENED:
        return {
            ...state,
            isCommunicationPreferencesModalOpened: action.payload
        };
    default:
        return state;
    }
};

export default promotions;

const getPromotions = (payload) => ({
    type: GET_PROMOTIONS,
    payload
});

export const getPromotionContent = (lang) => {
    const fail = () => ({
        type: GET_PROMOTIONS_ERROR
    });
    const loading = () => ({
        type: GET_PROMOTIONS_LOADING
    });

    return (dispatch, getState) => {
        dispatch(loading());
        promotionsRequest(lang)
            .then((res) => {
                const promotions = res.data.data.map((promo) => {
                    const promoStatus = setPromoStatus(promo.dateTimeStart, promo.dateTimeEnd);
                    const promoGames = promo.games.filter((gameId) => gameId !== null);
                    const gamesList = findPromoGamesList(promoGames, getState().games.data.games);

                    return {
                        ...promo,
                        ...promoStatus,
                        slug: promo.title.toLowerCase().replace(/\s/g, '-'),
                        background: promo.backgroundImage,
                        backgroundWEBP: promo.backgroundImageWEBP,
                        bigBackground: promo.backgroundImage,
                        bigBackgroundWEBP: promo.backgroundImageWEBP,
                        description: sanitizePromoData(promo.description),
                        howToParticipate: sanitizePromoData(promo.howToParticipate),
                        termsAndConditions: sanitizePromoData(promo.termsAndConditions),
                        games: gamesList,
                        tags: promo.tags.split(',').map((item) => item.trim()),
                        displayOnLobby: true
                    };
                });

                const sortedPromotions = [...promotions].sort((a, b) => {
                    // Define the order of statuses
                    const statusOrder = {
                        [PROMO_STATUS_ONGOING]: 0,
                        [PROMO_STATUS_UPCOMING]: 1
                    };

                    const orderA = statusOrder[a.status];
                    const orderB = statusOrder[b.status];

                    if (orderA !== undefined && orderB !== undefined) {
                        return orderA - orderB;
                    } else if (orderA !== undefined) {
                        return -1;
                    } else if (orderB !== undefined) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                dispatch(updatePromotionsList(sortedPromotions));
            })
            .catch(() => dispatch(fail()));
    };
};

export const setPromotionToBeUpdated = (payload) => ({
    type: SET_PROMOTION_TO_BE_UPDATED,
    payload
});

export const updatePromotionsList = (promotions) => {
    return (dispatch) => {
        const [promotionToBeChanged] = [...promotions]
            .filter((item) => item.timerTimestamp)
            .sort((a, b) => a.timerTimestamp - b.timerTimestamp);

        dispatch(getPromotions(promotions));
        dispatch(setPromotionToBeUpdated(promotionToBeChanged || null));
    };
};

export const setPromoActiveTab = (payload) => ({
    type: SET_PROMO_ACTIVE_TAB,
    payload
});

export const enrollPromotion = (promoId) => {
    const loading = () => ({
        type: ENROLL_PROMOTION,
    });

    const success = () => ({
        type: ENROLL_PROMOTION_SUCCESS,
    });

    const fail = () => ({
        type: ENROLL_PROMOTION_FAILED,
    });

    return (dispatch) => {
        dispatch(loading());
        enrollPromotionRequest(promoId)
            .then(() => {
                dispatch(success());
            })
            .catch(() => {
                dispatch(fail());
            })
            .finally(() => {
                dispatch(getCurrentPromoEnroll(promoId));
            });
    };
};

export const getCurrentPromoEnroll = (promoId) => {
    const loading = () => ({
        type: GET_CURRENT_PROMO_ENROLL_LOADING,
    });

    const success = (payload) => ({
        type: GET_CURRENT_PROMO_ENROLL,
        payload,
    });

    const fail = () => ({
        type: GET_CURRENT_PROMO_ENROLL_FAILED,
    });

    return (dispatch) => {
        dispatch(loading());
        getPromotionRequest(promoId)
            .then((res) => {
                dispatch(success({
                    isPromoEnrolled: res.data.data.isSubscribed
                }));
            })
            .catch(() => {
                dispatch(fail());
            });
    };
};

export const setIsCommunicationPreferencesModalOpened = (payload) => ({
    type: SET_IS_COMMUNICATION_PREFERENCES_MODAL_OPENED,
    payload
});
